import { IdateT } from 'shared/models';

export interface IUsersNew {
  login: string;
  name?: string;
  email?: string;
  role?: string;
  verify_pass: string;
  password?: string;
  connector?: string;
  link?: string;
  groups?: [];
}

export interface IUserItem {
  _id?: string;
  login: string;
  name?: string;
  email?: string;
  role?: string;
  active?: boolean;
  archive?: boolean;
  date_created?: {};
  verify_pass?: string;
  methods?: {};
  password?: string;
  connector?: string;
  groups?: string[];
  expiry?: IdateT | null;
  password_expiry?: IdateT | null;
  auth_failures?: number | null;
  auth_locked?: boolean | null;
}
export const IUserItemDefault = { login: '' };
export interface IUserUpdate {
  _id?: string;
  login: string;
  name: string;
  email: string;
  role: string;
  active: boolean;
  archive: boolean | string;
  date_created?: {};
  verify_pass?: string;
  connector?: string;
  sid: string;
  groups?: string[];
  last_login?: string;
  methods?: {};
  expiry?: IdateT | null;
  password_expiry?: IdateT | null;
  auth_failures?: number | null;
  auth_locked?: boolean | null;
}

export const IUserUpdateDefault = { login: '', name: '', email: '', role: '', active: false, archive: '', verify_pass: '', methods: {}, connector: '', sid: '', groups: [], expiry: null, password_expiry: null, auth_failures: null, auth_locked: null };
/*
methods:{"telegram":{"_id":{"$oid":"64e8d1a42df2eafa5f0982ca"},"is_linked":false,"link_id":0,"username_tg":"","link_valid":1692997620761}
*/
export interface IWUsersUpdateProfile {
  userdata: IUserUpdate;
}
