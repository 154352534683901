import { ApiCLDAPGetConnector, ApiCLDAPGetListUsers, DataHttpConnector } from 'modules/connectorLDAP/api';
import { ICLDAPItem, ICLDAPItemDefault, ICLDAPUsers } from 'entities/connectorLDAP/models';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'shared/context/auth';
import { usePageContext } from 'shared/context/page';
import { devLog } from 'shared/lib/default';
import { UIAlertError } from 'shared/ui/alert';
import { UIInfoNoAccess } from 'shared/ui/info';
import { UIBreadcrumbs, UITypographyH1 } from 'shared/ui/typography';
import { WCLDAPTableUsersList } from 'widgets/connectorLDAP/TableUsersList';

//========================
export const PageConnectorLDAPlistusers = () => {
  const pageCode: string = 'settings.сonnectors.ldap.listusers';
  const { updatePage, loadingPage, errorPage, setLoadingPage, setErrorPage } = usePageContext();
  //----
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const params = useParams(); // из url : id
  const connector_id = params.id ? params.id : '';
  //--- GetConnector
  const [connector, setConnector] = useState<ICLDAPItem>(ICLDAPItemDefault);
  const { CLDAPGetConnector } = ApiCLDAPGetConnector(connector_id);
  const dataConnector = useCallback(async () => {
    try {
      const ress: DataHttpConnector = await CLDAPGetConnector();
      if (ress.data.config === null) {
        ress.data.config = { upn_as_login: false };
      }
      setConnector(ress.data);
    } catch (e) {
      if (e instanceof Error) {
        devLog('catch e ', e.message);
      }
    }
  }, [CLDAPGetConnector]);
  //--- GetListUsers
  const [list, setlist] = useState<ICLDAPUsers[]>([]);
  const { loading, CLDAPGetListUsers, error } = ApiCLDAPGetListUsers(connector_id);
  const dataList = useCallback(async () => {
    try {
      const resUsers = await CLDAPGetListUsers();
      setlist([]);
      const listNew: ICLDAPUsers[] = resUsers.data;

      listNew.map((item) => {
        setlist((list) => {
          return [
            ...list,
            {
              sid: item.sid,
              name: item.name,
              lastname: item.lastname,
              login: item.login,
              upn: item.upn == '' ? 'error' : item.upn,
              email: item.email,
              groups: item.groups,
              connector: item.connector,
              linked: item.linked,
              disabled: item.disabled,
              local_id: item.local_id,
              local_active: item.local_active,
              local_archived: item.local_archived,
            },
          ];
        });
      });
    } catch (e) {
      if (e instanceof Error) {
        devLog('catch e ', e.message);
      }
    }
  }, [connector, CLDAPGetListUsers, list]);
  //--- GetStatusUsersConnector - добавлен, нет
  /*
    const ApiCLDAPGetListUsers = useCallback(
        async (connector_id: string) => {
            let url = '/api/ldap/' + connector_id + '/users';
            try {
                const result = await request(url);
                if (result.data) {
                    setlist(result.data);
                   // JScriptInit("/assets/js/custom/datatable.js");
                    devLog(url, result);
                }
                if (error) { devLog(url, error); }
            } catch (e: any) {
                devLog(url, e.name + ' ' + e.message);
            }
        }, []);
*/

  //---
  useEffect(() => {
    dataConnector();
    dataList();
  }, []);
  //============= отслеживание
  useEffect(() => {
    setLoadingPage(loading);
  }, [loading, setLoadingPage]);
  useEffect(() => {
    setErrorPage(error);
  }, [error, setErrorPage]);
  //--- page, limit
  useEffect(() => {
    dataConnector();
    dataList();
  }, [updatePage]);

  // <UITypographyH1 pageCode={pageCode} loading={loadingPage} addtoname={'"'+connector.name+'"'} />
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <UIBreadcrumbs pageCode={pageCode} loading={loadingPage} />
      <UITypographyH1 pageCode={pageCode} loading={loadingPage} />
      {errorPage && <UIAlertError text={errorPage} />}
      <h3>
        Коннектор "{connector.name}" <small>[ id: {connector_id} ]</small>
      </h3>

      {list && connector.config && <WCLDAPTableUsersList list={list} connector_id={connector_id} upn_as_login={connector.config.upn_as_login == true ? true : false} />}
    </>
  );
};
