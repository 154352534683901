import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
//---
import { AuthContext } from 'entities/users/AuthContext';
import { dataPagesMenu } from 'app/data/default';
//---
import { devLog } from 'shared/lib/default';
import { UIAlertError } from 'shared/ui/alert';
import { UIInfoLoader } from 'shared/ui/info';
import { UITypographyH1 } from 'shared/ui/typography';
import { FConnectorLDAP } from 'features/connectorLDAP/ui/TestConnection';
//---
import { ICLDAPItem } from 'entities/connectorLDAP/models';
import { ApiCLDAPGetList } from 'modules/connectorLDAP/api';
import { FCLDAPAutoProvision } from 'features/connectorLDAP/ui/AutoProvision';
import { FNotificationEmailNav } from 'features/NotificationEmail/NavTabs';
import { ApiNEmailServersList, ApiNEmailServersNew } from 'entities/NotificationEmail/api';
import { useAuthContext } from 'shared/context/auth';

export const PageNEmailServersList = () => {
  //=============
  const pageCode: string = 'settings.notification.email.servers.list';
  //const dataPage = dataPagesMenu["settings.сonnectors.ldap.list"];

  //=============
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const [list, setlist] = useState<ICLDAPItem[]>([]);

  const { loading, response: List, error, success } = ApiNEmailServersList();
  const dataList = useCallback(async () => {
    try {
      const res = await List();
      setlist(res.data);
    } catch (e) {
      if (e instanceof Error) {
        devLog(pageCode, e.message);
      }
    }
  }, [List]);
  //=============
  useEffect(() => {
    dataList();
  }, []);
  //=============
  return (
    <>
      <UITypographyH1 pageCode={pageCode} />
      {error && <UIAlertError text={error} />}
      {(auth.userrole == 'superadmin' || auth.userrole == 'admin') && (
        <div>
          <div className="panel-container show">
            <div className="panel-content">
              <div className="card border">
                <FNotificationEmailNav />
                <div className="card-body">
                  <div className="float-right">
                    <NavLink to="/settings/notification-email/servers/new" className="btn mt-2 mb-2  btn-warning waves-effect waves-themed">
                      <span className="fal fa-plus mr-1"> Добавить</span>
                    </NavLink>
                  </div>
                  <div className="mb-3">
                    <br />
                    <br />
                  </div>
                  <hr />
                  {loading && <UIInfoLoader />}
                  {!list.length && <div className="text-center">Нет серверов</div>}
                  {list.length > 0 && (
                    <>
                      <div id="js_list_accordion_c" className="accordion border accordion-hover accordion-clean mb-g">
                        {list &&
                          list.map((item) => {
                            return (
                              <div className="card" key={item._id}>
                                <div className="card-header bg-primary-50">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                    className="card-title collapsed"
                                    data-toggle="collapse"
                                    data-target={'#js_list_' + item._id}
                                    aria-expanded="false"
                                    data-filter-tags="merge"
                                  >
                                    <span className=" badge badge-primary mr-1 ">
                                      <i className="fal fa-mail-bulk width-2 fs-xl"></i>
                                    </span>{' '}
                                    {item.name}
                                    <span className="ml-auto">
                                      <span className="collapsed-reveal">
                                        <i className="fal fa-chevron-up fs-xl"></i>
                                      </span>
                                      <span className="collapsed-hidden">
                                        <i className="fal fa-chevron-down fs-xl"></i>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                                <div id={'js_list_' + item._id} className="collapse" data-parent="#js_list_accordion_c">
                                  <div className="card-body bd-gray-light  ">
                                    {/* content on */}
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="card shadow-1 shadow-hover-5 mb-g">
                                          <div className="card-body p-3">
                                            {/*========== settings on */}
                                            <div className="row mb-2">
                                              <div className="col-6">
                                                <h5>Настройки</h5>
                                              </div>
                                              <div className="col-6 text-right">
                                                <Link className="btn btn-warning bg-warning-100 btn-icon waves-effect waves-themed" to={'/settings/connector-ldap-update/' + item._id}>
                                                  <i className="fal fa-pen "></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <table className="table table-bordered table-hover table-striped mb-0 ">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <b>id:</b>
                                                  </td>
                                                  <td>{item._id}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>server:</b>
                                                  </td>
                                                  <td>{item.server}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>base:</b>
                                                  </td>
                                                  <td>{item.base}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>login:</b>
                                                  </td>
                                                  <td>{item.login}</td>
                                                </tr>
                                                {/*<tr><td><b>groups:</b></td><td>{item.groups}</td></tr>*/}
                                                <tr>
                                                  <td>
                                                    <b>Поле login при импорте:</b>
                                                  </td>
                                                  <td>
                                                    {item.config === null && <>login</>}
                                                    {item.config !== null && !item.config.upn_as_login && <>login</>}
                                                    {item.config !== null && item.config.upn_as_login && <>upn</>}
                                                  </td>
                                                </tr>
                                                {item.config !== null && item.config.request_limit && (
                                                  <tr>
                                                    <td>
                                                      <b>Лимит объектов в запросе:</b>
                                                    </td>
                                                    <td>{item.config.request_limit}</td>
                                                  </tr>
                                                )}
                                                <tr>
                                                  <td>
                                                    <b>filter:</b>
                                                  </td>
                                                  <td>{item.filter}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            {/*========== settings off */}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-xl-6">
                                        <div className="card shadow-1 shadow-hover-5 mb-1">
                                          <div className="card-body p-3">
                                            {/*========== connection on */}
                                            <FConnectorLDAP id={item._id} />
                                            {/*========== connection off */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* content off */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
