import { ICLDAPUsers } from "entities/connectorLDAP/models";
import { FUserActiveButton } from "features/users/ui/UserActiveButton";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { FCLDAPuserImportNew } from "features/connectorLDAP/ui/UserImportNew";
import { FCLDAPuserLinkUpdate } from "features/connectorLDAP/ui/UserLinkUpdate";
import { ELLDAPuserLogin } from "entities/connectorLDAP/lib";
import { usePageContext } from "shared/context/page";
import { UIInfoLoader } from "shared/ui/info";

//import { EUsersGroups } from "entities/users/ui";
type ICLDAPTableUsersList = {
    list: ICLDAPUsers[];
    connector_id: string;
    upn_as_login: boolean;
}
type TadfButtons = {
    item: any;
    index: any;
}

export function WCLDAPTableUsersList({ list, connector_id, upn_as_login }: ICLDAPTableUsersList) {
    //---
    const { updatePage } = usePageContext();
    const [dataTable, setDataTable] = useState<ICLDAPUsers[]>([]);
    const [modalData, setModalData] = useState<any>({});
    function AdfButtons({ item, index }: TadfButtons) {
        // disabled - статус в коннекторе. true - деактивирован
        return (<>
            {/*
        disabled={item.disabled?'true':'false'}<br />
        local_active={item.local_active?'true':'false'}
        */}
            {item.disabled == true && item.local_id && item.local_active == true && <>
                {/* Кнопка деактивировать */
                    item.local_id && <FUserActiveButton user_id={item.local_id} active={true} />}
            </>}

            {(item.upn != "error" && item.disabled !== true) && <>
                {/*<FCLDAPImportUsersList key={index + item.login} user={item} connector_id={connector_id} active={item.local_active} upn_as_login={(upn_as_login == true) ? true : false} />*/}
                <div className="row col-ldap-buttons g-0">
                    <div className="col-2 align-self-center  ">
                        {item.local_active && item.local_active === true ? <>
                            <div className="  icon-align">
                                <i className="fas fa-lightbulb text-success " title="Активен в ADF"></i>
                            </div>
                        </> : <></>}
                    </div>
                    <div className="col-10">
                        {item.linked == null &&
                            <>
                                {/* кнопка Импортировать*/}
                                <FCLDAPuserImportNew id_random={index} userData={item} connector_id={connector_id} upn_as_login={(upn_as_login == true) ? true : false} />
                            </>
                        }
                        {item.linked == true &&
                            <>
                                Привязан
                            </>
                        }
                        {item.linked == false &&
                            <>

                                {/* кнопка Привязать */}
                                <FCLDAPuserLinkUpdate id_random={index} user_id={item.local_id} login={ELLDAPuserLogin(item, (upn_as_login == true) ? true : false)} connector_id={connector_id} groups={item.groups} />
                            </>
                        }
                    </div>
                </div>

            </>
            }
        </>);
    }
    //---
    function adfStatus(item: any) {
        let adf = '';
        if (item.local_active === true) { adf = 'Активен'; }
        if (item.local_active === false) { adf = 'Деактивирован'; }
        if (item.linked == null && item.disabled != true) { adf = 'Импортировать'; }
        if (item.linked == true) { adf = adf + '. Привязан'; }
        if (item.linked == false) { adf = adf + '. Привязать'; }
        if (item.disabled == true && item.local_active == true) { adf = 'Деактивировать'; }

        return adf;
    }
    //---
    const options = {
        filter: false,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 500],
        textLabels: {
            body: {
                noMatch: "Данные загружаются",
                toolTip: "Sort"
            },
            pagination: {
                next: "Следующая",
                previous: "Предыдущая",
                rowsPerPage: "На странице:",
                displayRows: "of",
            },
            toolbar: {
                search: "Поиск",
                downloadCsv: "Скачать CSV",
                print: "Печать",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
            },
            filter: {
                all: "Все",
                title: "Фильтры",
                reset: "Сбросить",
            },
            viewColumns: {
                title: "Показать колонки",
                titleAria: "Show/Hide Table Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
            }
        },
        downloadOptions: {
            filename: 'conneсtor.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true
            }
        },
        selectableRowsHideCheckboxes: true,
    }
    const columns = [
        {
            name: "disabledicon",
            label: "LDAP",
            download: true,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any) => (
                    <>
                        {tableMeta.tableData[tableMeta.rowIndex].disabled === true ? <><i className="fas fa-address-card text-danger" title="деактивирован в ldap"></i></> : <><i className="fas fa-address-card text-success" title="активен в ldap"></i></>}
                    </>
                )
            }
        },
        {
            name: "adf",
            label: "ADF",
            download: true,
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <>
                        <AdfButtons item={tableMeta.tableData[tableMeta.rowIndex]} index={tableMeta.rowIndex} />
                    </>
                )
            }
        },
        {
            name: "login",
            label: "login",
            download: true,
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "name",
            label: "name",
            download: true,
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "lastname",
            label: "lastname",
            download: true,
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "upn",
            label: "upn",
            download: true,
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "email",
            label: "email",
            download: true,
            options: {
                filter: false,
                sort: true
            }
        },
 
        {
            name: "groups",
            label: "groups",
            download: true,
            display: false,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any) => (
                    <div className="row">
                        <div className="col-6 align-middle pt-2">{value.length} </div>
                        <div className="col-6">
                            <button className="btn btn-outline-primary  waves-effect waves-themed"
                                data-toggle="modal" data-target=".default-example-modal-right"
                                onClick={() => { console.log(tableMeta); OpenModalInfo(tableMeta.tableData[tableMeta.rowIndex]) }}
                            >
                                <i className="fal fa-info"></i>
                            </button>
                        </div>

                        {/*
                        <ul >
                            {value.map((item: any, index: any) => {
                                return (
                                    <li key={index}>{item}</li>
                                )
                            })}
                        </ul>*/}
                    </div>
                )
            }
        }


    ];
/*
,
        {
            name: "infobutton",
            label: "more",
            download: false,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <button className="btn btn-outline-primary  waves-effect waves-themed"
                        data-toggle="modal" data-target=".default-example-modal-right"
                        onClick={() => { console.log(tableMeta); OpenModalInfo(tableMeta.tableData[tableMeta.rowIndex]) }}
                    >
                        <i className="fal fa-info"></i>
                    </button>
                )
            }

        }
*/
    //---
    function makeData(dataInput: ICLDAPUsers[]) {
        let data: any = [];
        dataInput.map((item: any, index) => {
            //disabled enabled
            item.disabledicon = item.disabled === true ? 'Деактивирован' : 'Активен';
            //item.groups = item.groups;
            /*
            item.groupsbutton = () => (<>
                <button className="btn btn-outline-primary  waves-effect waves-themed"
                    data-toggle="modal" data-target=".default-example-modal-right"
                    onClick={() => OpenModalInfo(item)}
                >
                    Группы <span className="  badge bg-primary-300 ml-2">{item.groups.length}</span>
                </button>
            </>);
            */
            /*
                        item.infobutton = () => (<>
                            <button className="btn btn-outline-primary  waves-effect waves-themed"
                                data-toggle="modal" data-target=".default-example-modal-right"
                                onClick={() => OpenModalInfo(item)}
                            >
                                <i className="fal fa-info"></i>
                            </button>
                        </>);
            */
            // item.infobutton = item._id;
            //-----
            item.adf = adfStatus(item);
            data.push(item);
        })
        return data;
    }
    function OpenModalInfo(item: {}) {
        setModalData(item);
        return true;
    }
    //=====
    useEffect(() => {
        setDataTable(makeData(list));
        //setDataTable(list);
    }, [list]);
    return (<>
        <MUIDataTable
            title={""}
            data={dataTable}
            columns={columns}
            options={options}
        />
        {/*  Modal*/}
        <div className="modal fade default-example-modal-right" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-right">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title h4">Пользователь: {modalData.login}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setModalData({}); }}>
                            <span aria-hidden="true"><i className="fal fa-times"></i></span>
                        </button>
                    </div>
                    <div className="modal-body audit-detail">
                        <div className="row">
                            <div className="col-6">
                                <h3>Данные</h3>
                            </div>
                            <div className="col-6 text-right">
                                {modalData.local_id ? <><a href={`/user/update/${modalData.local_id}`}
                                    className="btn  btn-primary waves-effect waves-themed mb-3"
                                    title="Редактировать" target="_blank">Редактировать <i className="fa fa-external-link"></i></a></> : <> </>}
                            </div>
                        </div>
                        <table className="table table-bordered table-hover table-striped w-100">
                            <tbody>
                            
                            <tr>
                                    <td>
                                    sid
                                    </td>
                                    <td>
                                        {modalData.sid}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        login
                                    </td>
                                    <td>
                                        {modalData.login}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        name
                                    </td>
                                    <td>
                                        {modalData.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        lastname
                                    </td>
                                    <td>
                                        {modalData.lastname}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        upn
                                    </td>
                                    <td>
                                        {modalData.upn}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        email
                                    </td>
                                    <td>
                                        {modalData.email}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <h3 className="color-primary-300" >{/*color-primary-300*/}
                            <b>Группы
                                {modalData.groups && <span className="badge bg-primary-300 ml-2">{modalData.groups.length}</span>}
                            </b>
                        </h3>
                        <table className="table table-bordered table-hover table-striped w-100">
                            <tbody>
                                {modalData.groups && modalData.groups.map((itemGroup: any) => {
                                    return (<>
                                        <tr >
                                            <td>{itemGroup}</td>
                                        </tr></>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>);
}