import { LANG_default } from 'app/data/lang';
import { dataPagesMenu } from 'app/data/default';
import { ICLDAPItem, ICLDAPItemDefault } from 'entities/connectorLDAP/models';
import { useHttp } from 'hooks/http.hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UIAlertError, UIAlertSuccess } from 'shared/ui/alert';
import { FormCheckbox, FormInput, FormPreventDefault, FormSelect } from 'shared/ui/form';
import { UIInfoLoader, UIInfoNoAccess } from 'shared/ui/info';
import { useAuthContext } from 'shared/context/auth';
import { WCLDAPUpdateConfigTrack } from 'modules/connectorLDAP/widgets/WUpdateConfigTrack';

export const PageConnectorLDAPupdate = () => {
  const pageCode: string = 'settings.сonnectors.ldap.update';
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----
  const language = 'ru';

  const dataPage = dataPagesMenu['settings.сonnectors.ldap.update'];

  //=============
  const params = useParams(); // из url : id
  const { loading, request, error } = useHttp();
  const [form, setForm] = useState<ICLDAPItem>(ICLDAPItemDefault);
  const [dataold, setDataOld] = useState<ICLDAPItem>(ICLDAPItemDefault);
  //========================== Form Messame
  const [msgsuccess, setMsgSuccess] = useState('');
  const [msgerror, setMsgError] = useState('');

  //-------------
  function cleanMsg() {
    setMsgSuccess('');
    setMsgError('');
  }
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    //console.log(event.target.value);
  };
  const changeHandlerChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
    console.log(event.target.checked);
  };

  const changeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    console.log();
  };
  //============= API request
  const getData = useCallback(async () => {
    try {
      cleanMsg();
      const data = await request('/api/ldap/' + params.id);
      if (data.data) {
        console.log('/api/ldap/:id', data);
        if (data.data.config) {
          if (data.data.config.request_limit) {
            data.data.requestlimit = data.data.config.request_limit;
          } else {
            data.data.requestlimit = '500';
          }
          data.data.configlogin = data.data.config.upn_as_login == true ? 'upn' : 'login';
          if (data.data.config.auto_provision_schedule == null) {
            data.data.autocheduleimport = false;
            data.data.autocheduledeactivate = false;
          } else {
            data.data.autocheduleimport = data.data.config.auto_provision_schedule.import;
            data.data.autocheduledeactivate = data.data.config.auto_provision_schedule.deactivate;
          }
          if (data.data.config.chpass_enabled) {
            data.data.chpassenabled = data.data.config.chpass_enabled;
          } else {
            data.data.chpassenabled = false;
          }
          if (data.data.config.chpass_helper_group_dn) {
            data.data.chpasshelpergroupdn = data.data.config.chpass_helper_group_dn;
          } else {
            data.data.chpasshelpergroupdn = '';
          }
        }
        setForm(data.data);
        setDataOld(data.data);
      }
      if (error) {
        setMsgError(error);
      }
    } catch (e) {
      console.log(e);
      setMsgError(LANG_default[language].error500);
    }
  }, [request]);

  //============
  const saveHandler = async () => {
    cleanMsg();
    try {
      if (form.name == '' || form.server == '' || form.base == '' || form.login == '') {
        setMsgError('Заполните обязательные поля');
      } else {
        if (form.filter == '' || dataold.filter == '') {
          setForm({
            ...form,
            filter: '(&(&(objectCategory=person)(objectClass=user))(|(sAMAccountName={query})(cn={query})(userprincipalname={query})))',
          });
        }
        if (form.name != dataold.name && form.name != '') {
          const dataName = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            name: form.name,
          });
        }

        if (form.server != dataold.server && form.server != '') {
          const dataServer = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            server: form.server,
          });
        }
        if (form.base != dataold.base && form.base != '') {
          const database = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            base: form.base,
          });
        }
        if (form.filter != dataold.filter && form.filter != '') {
          const datafilter = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            filter: form.filter,
          });
        }
        if (form.login != dataold.login && form.login != '') {
          const datalogin = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            login: form.login,
          });
        }
        if (form.pass != dataold.pass && form.pass != '') {
          const datapass = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            pass: form.pass,
          });
        }

        if (form.requestlimit != dataold.requestlimit || form.configlogin != dataold.configlogin) {
          if (form.requestlimit == '') {
            form.requestlimit = '500';
          }
          if (form.configlogin == '') {
            form.configlogin = 'login';
          }
          const dataconfig = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            config: {
              upn_as_login: form.configlogin == 'upn' ? true : false,
              request_limit: Number(form.requestlimit),
            },
          });
        }
        console.log('form.autocheduleimport', form.autocheduleimport);
        console.log('form.autocheduledeactivate', form.autocheduledeactivate);
        if (form.autocheduleimport != dataold.autocheduleimport || form.autocheduledeactivate != dataold.autocheduledeactivate) {
          if (form.autocheduleimport == false && form.autocheduledeactivate == false) {
            console.log('DELETE');
            const dataconfig = await request('/api/ldap/' + params.id + '/config/auto_provision_schedule', 'DELETE');
          } else {
            const dataconfig = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
              config: {
                auto_provision_schedule: {
                  import: form.autocheduleimport,
                  deactivate: form.autocheduledeactivate,
                },
              },
            });
          }
        }
        if (form.chpassenabled != dataold.chpassenabled || form.chpassenabled != dataold.chpasshelpergroupdn) {
          const dataconfig = await request('/api/ldap/' + params.id + '/update', 'PATCH', {
            config: {
              chpass_enabled: form.chpassenabled,
              chpass_helper_group_dn: form.chpasshelpergroupdn,
            },
          });
        }

        setMsgSuccess('Данные успешно сохранены');

        {
          /* const dataArray : ICLDAPItem ={
                    _id: params.id?params.id:defaultID,
                    name: form.name, 
                    server: form.server, 
                    base: form.base, 
                    filter: form.filter, 
                    login: form.login?form.login:defaultLogin, 
                    pass:form.pass?form.pass:defaultPass
                };
                const dataName = await request('/api/ldap/' + params.id + '/update', 'PATCH', dataArray);
                if (error) {
                    setMsgError(error);
                } else {
                    setMsgSuccess('Данные успешно сохранены');
                }
                 */
        }
      }
    } catch (e) {
      console.log(e);
      setMsgError(LANG_default[language].error500);
    }
  };

  useEffect(() => {
    document.title = dataPage.name;
    getData();
  }, []);
  //============
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div id="panel-1" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="subheader">
                  <h1 className="subheader-title text-center mt-30">Редактивание коннектора LDAP "{form.name}"</h1>
                </div>
                <div className="row mt-60 mb-60">
                  <div className="col-12 ">
                    {loading && <UIInfoLoader />}
                    {msgerror && <UIAlertError text={msgerror} />}
                    {msgsuccess && <UIAlertSuccess text={msgsuccess} />}

                    <form onSubmit={FormPreventDefault}>
                      <FormInput label="name" name="name" fOnChange={changeHandler} value={form.name} required={true} helpingText="Название коннектора должно быть уникальным" />
                      <FormInput label="server" name="server" fOnChange={changeHandler} value={form.server} required={true} helpingText="ldaps://10.1.1.2:636" />
                      <FormInput label="base" name="base" fOnChange={changeHandler} value={form.base} required={true} helpingText="cn=Users,dc=domain,dc=local" />
                      <FormInput label="filter" name="filter" fOnChange={changeHandler} value={form.filter} required={true} helpingText="(&(&(objectCategory=person)(objectClass=user))(|(sAMAccountName={query})(cn={query})(userprincipalname={query})))" />
                      <FormInput label="login" name="login" fOnChange={changeHandler} value={form.login} required={true} helpingText="ldap@domain.local" />
                      <FormInput label="pass" name="pass" fOnChange={changeHandler} value={form.pass} type="password" />

                      <br />
                      <hr />
                      <h3 className="mt-3 "> Параметры импорта</h3>
                      <br />
                      <FormSelect
                        label="Соответствие поля login при импорте"
                        name="configlogin"
                        fOnChange={changeSelectHandler}
                        value={form.configlogin}
                        optionValues={[
                          { value: 'login', text: 'login' },
                          { value: 'upn', text: 'upn' },
                        ]}
                      />
                      <FormInput label="Лимит объектов в запросе" name="requestlimit" fOnChange={changeHandler} value={form.requestlimit} helpingText="от 10 до 10тыс " />
                      <br />
                      <hr />
                      <h3 className="mt-3 "> Aвтоимпорт пользователей по расписанию</h3>
                      <br />
                      <div>
                        При активации одного из параметров запускается <b>обновление</b> данных пользователя: группы, email, перепривязка к коннектору и формат логина.
                      </div>
                      <br />
                      <FormCheckbox label="Импорт" name="autocheduleimport" fOnChange={changeHandlerChecked} value={form.autocheduleimport} />
                      <FormCheckbox label="Деактивация/архивация" name="autocheduledeactivate" fOnChange={changeHandlerChecked} value={form.autocheduledeactivate} />
                      <br />
                      <hr />
                      <h3 className="mt-3 "> Селфсервис</h3>
                      <br />
                      <table className="table table-bordered table-hover table-striped mb-0  ">
                        <tbody>
                          <tr>
                            <td className="col-3">Активация:</td>
                            <td>
                              <FormCheckbox label="" name="chpassenabled" fOnChange={changeHandlerChecked} value={form.chpassenabled} />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-3">Helper group:</td>
                            <td>
                              <FormInput label="" name="chpasshelpergroupdn" fOnChange={changeHandler} value={form.chpasshelpergroupdn} helpingText="helper group dn " />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <br />
                      <hr className="mt-1 mb-1" />

                      <div className="row">
                        <div className="col-6">
                          <Link to="/settings/connector-ldap" className="btn btn-default waves-effect waves-themed">
                            Отменить
                          </Link>
                        </div>
                        <div className="col-6 text-right">
                          <button type="submit" className="btn btn-primary waves-effect waves-themed" onClick={saveHandler} disabled={loading}>
                            Сохранить
                          </button>
                        </div>
                      </div>
                    </form>

                    <br />
                    <hr />
                    <h3 className="mt-3 ">Ослеживать срок действия:</h3>
                    <br />

                    <table className="table table-bordered table-hover table-striped mb-0  ">
                      <tbody>
                        <tr>
                          <td className="col-3">
                            <b>Аккаунт:</b>
                          </td>
                          <td>
                            <WCLDAPUpdateConfigTrack id={params.id} name="track_account_expiration" value={form.config?.track_account_expiration ? true : false} />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Пароль:</b>
                          </td>
                          <td>
                            <WCLDAPUpdateConfigTrack id={params.id} name="track_password_expiration" value={form.config?.track_password_expiration ? true : false} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 bg-faded ">
                <div className="mt-2 panel-tag">
                  <span className="text-danger">*</span> - обязательные поля
                </div>
                <div className="mt-2">
                  <FormCheckbox label="- сохраняется поле нажатия кнопки Сохранить" name="test2" value={true} />
                </div>
                <div className="mt-2">
                  <FormCheckbox label="- автосохраняется" name="test1" value={true} typeswitch={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
