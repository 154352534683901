import { ICLDAPNew } from 'entities/connectorLDAP/models';
import { useHttp } from 'hooks/http.hooks';
import { useMessage } from 'hooks/message.hook';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UIAlertError, UIAlertSuccess } from 'shared/ui/alert';
import { FormInput, FormPreventDefault, FormSelect } from 'shared/ui/form';
import { UIInfoLoader, UIInfoNoAccess } from 'shared/ui/info';
import { useAuthContext } from 'shared/context/auth';

export const PageConnectorLDAPnew = () => {
  const pageCode: string = 'settings.notification.email.servers.new';
  //const auth = useContext(AuthContext);
  const auth = useAuthContext();
  //-----

  const { loading, error, request, clearError, success } = useHttp(); // error
  const CleanForm = {
    name: '',
    server: '',
    base: '',
    filter: '',
    login: '',
    pass: '',
    config: 'login',
    requestlimit: '500',
  };
  const [form, setForm] = useState(CleanForm);
  //========================== Form Messame
  const message = useMessage();
  const [msgsuccess, setMsgSuccess] = useState('');
  const [msgerror, setMsgError] = useState('');
  //------------ обновление данных формы
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    //console.log(event.target.value);
  };
  const changeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    console.log();
  };
  //===========
  const registerHandler = async () => {
    try {
      console.log(form);
      if (
        form.name == '' ||
        form.server == '' ||
        form.base == '' ||
        form.filter == '' ||
        form.login == '' ||
        form.pass == ''
      ) {
        setMsgError('Заполните обязательные поля');
        //message('Логин - обязательное поле для заполнения');
      } else {
        const dataArray: ICLDAPNew = {
          name: form.name,
          server: form.server,
          base: form.base,
          filter: form.filter,
          login: form.login,
          pass: form.pass,
          config: {
            upn_as_login: form.config == 'upn' ? true : false,
            request_limit: form.requestlimit != '' ? Number(form.requestlimit) : 500,
          },
        };
        // if(form.verifypass != ''){dataArray.verify_pass=form.verifypass;}
        console.log('dataArray!!', dataArray);
        const data = await request('/api/ldap/connectors', 'POST', dataArray);
        console.log('data', data);
        setMsgSuccess(`Коннектор ${form.name} [${form.login}] успешно создан`);
        setForm(CleanForm);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {}, [form]);
  if (auth.accessRights(pageCode) != true) {
    return <UIInfoNoAccess />;
  }
  return (
    <>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div id="panel-1" className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <div className="subheader">
                  <h1 className="subheader-title text-center mt-30">
                    Добавление нового коннектора LDAP
                  </h1>
                </div>
                <div className="row mt-60 mb-60">
                  <div className="col-12 ">
                    {loading && <UIInfoLoader />}
                    {msgerror && <UIAlertError text={msgerror} />}
                    {msgsuccess && <UIAlertSuccess text={msgsuccess} />}

                    <form onSubmit={FormPreventDefault}>
                      <FormInput
                        label="name"
                        name="name"
                        fOnChange={changeHandler}
                        value={form.name}
                        required={true}
                        helpingText="Название коннектора должно быть уникальным"
                      />
                      <FormInput
                        label="server"
                        name="server"
                        fOnChange={changeHandler}
                        value={form.server}
                        required={true}
                        helpingText="ldaps://10.1.1.2:636"
                      />
                      <FormInput
                        label="base"
                        name="base"
                        fOnChange={changeHandler}
                        value={form.base}
                        required={true}
                        helpingText="cn=Users,dc=domain,dc=local"
                      />
                      <FormInput
                        label="filter"
                        name="filter"
                        fOnChange={changeHandler}
                        value={form.filter}
                        required={true}
                        helpingText="(&(&(objectCategory=person)(objectClass=user))(|(sAMAccountName={query})(cn={query})(userprincipalname={query})))"
                      />
                      <FormInput
                        label="login"
                        name="login"
                        fOnChange={changeHandler}
                        value={form.login}
                        required={true}
                        helpingText="ldap@domain.local"
                      />
                      <FormInput
                        label="pass"
                        name="pass"
                        fOnChange={changeHandler}
                        value={form.pass}
                        type="password"
                        required={true}
                      />
                      <FormSelect
                        label="Соответствие поля login при импорте"
                        name="config"
                        fOnChange={changeSelectHandler}
                        value={form.config}
                        optionValues={[
                          { value: 'login', text: 'login' },
                          { value: 'upn', text: 'upn' },
                        ]}
                        required={true}
                      />
                      <FormInput
                        label="Лимит объектов в запросе"
                        name="requestlimit"
                        fOnChange={changeHandler}
                        value={form.requestlimit}
                        helpingText="от 10 до 10тыс "
                      />

                      <hr className="mt-30" />
                      <div className="mb-30">
                        <span className="text-danger">*</span> - обязательные поля
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <Link
                            to="/settings/connector-ldap"
                            className="btn btn-default waves-effect waves-themed"
                          >
                            Отменить
                          </Link>
                        </div>
                        <div className="col-6 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-themed"
                            onClick={registerHandler}
                            disabled={loading}
                          >
                            Сохранить
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
